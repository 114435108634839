interface CardStatsProps {
    attack: number;
    range: number;
    life: number;
    movementSpeed: number;
  }
  
  const CardStats = ({ attack, range, life, movementSpeed }: CardStatsProps) => (
    <div className="grid grid-cols-2 gap-x-2 mt-1 text-sm text-gray-600">
      <div>ATK: {attack}</div>
      <div>RNG: {range}</div>
      <div>HP: {life}</div>
      <div>SPD: {movementSpeed}</div>
    </div>
  );
  export default CardStats;