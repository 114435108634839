interface CardEffectsProps {
    effects: string[];
  }
  
  const CardEffects = ({ effects }: CardEffectsProps) => (
    <div className="mt-2">
      <div className="text-sm font-medium text-gray-700">Effects:</div>
      <div className="max-h-16 overflow-y-auto">
        <ul className="text-xs text-gray-600 space-y-1 list-disc pl-4">
          {effects.map((effect, index) => (
            <li key={index} className="line-clamp-2">{effect}</li>
          ))}
        </ul>
      </div>
    </div>
  );
  export default CardEffects;