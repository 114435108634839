// islands/CardManager.tsx
import { useEffect, useState } from "preact/hooks";
import DatabaseSelector from "./DatabaseSelector.tsx";
import CollectionEditor from "./CollectionEditor/CollectionEditor.tsx";
import HexGridEditor from "./HexGridEditor.tsx";
// deno-lint-ignore-file no-explicit-any
export interface Card {
  Name: string;
  Type?: string;
  Attack?: number;
  Range?: number;
  Life?: number;
  MovementSpeed?: number;
  Initiative: number;
  Cost: number;
  EffectExpressions?: string[];
  Effects: Array<{
    Trigger: string;
    Type: string;
    Description: string;
  }>;
}

interface CardManagerProps {
  initCards: Card[];
  initialDatabase: string;
}

export default function CardManager({ initCards, initialDatabase }: CardManagerProps) {
  const [selectedTab, setSelectedTab] = useState('collection');
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [existingLevels, setExistingLevels] = useState<number[]>([]);
  const [cards, setCards] = useState(initCards);
  
  const [currentDatabase, setCurrentDatabase] = useState(initialDatabase);
  const [error, setError] = useState<string | null>(null);
  const loadLevels = async () => {
    try {
      const response = await fetch(`/api/levelEditor?database=${encodeURIComponent(currentDatabase)}&levelNumber=1`);
      if (!response.ok) throw new Error("Failed to load levels");
      const data = await response.json();
      setExistingLevels(data.existingLevels);
    } catch (err) {
      setError("Failed to load levels");
    }
  };

  useEffect(() => {
    loadLevels();
  }, [currentDatabase]);

  const handleDatabaseSelect = async (database: string) => {
    try {
      const response = await fetch(`/?database=${database}`);
      if (!response.ok) throw new Error("Failed to fetch data");
      const data = await response.json();
      setCards([...data.units, ...data.spells]);
      setCurrentDatabase(database);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCardUpdate = async (fileType: string, cardData: string) => {
    try {
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("fileType", fileType);
      formData.append("cardData", JSON.stringify(cardData));
      formData.append("database", currentDatabase);

      const response = await fetch("/api/updateCard", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to update card");
      const data = await response.json();
      // setCards([...data.units, ...data.spells]);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const TabButton = ({ id, label }: { id: string; label: string }) => (
    <button
      onClick={() => setSelectedTab(id)}
      className={`flex-1 py-3 px-6 text-lg font-medium transition-colors
        ${selectedTab === id 
          ? 'bg-white border-t-2 border-blue-500 text-blue-600' 
          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
    >
      {label}
    </button>
  );

  return (
    <div className="p-4 mx-auto w-full">
      <h1 className="text-3xl font-bold mb-8">Mythological Card Manager</h1>

      <DatabaseSelector currentPath={currentDatabase} onSelect={handleDatabaseSelect} />

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="mt-6">
        {/* Tab Header */}
        <div className="flex border-b">
          <TabButton id="collection" label="Collection Editor" />
          <TabButton id="level" label="Level Editor" />
        </div>

        {/* Tab Content */}
        <div className="bg-white border-x border-b rounded-b-lg p-6">
          {selectedTab === 'collection' && (
            <CollectionEditor database={currentDatabase} onCardUpdate={handleCardUpdate}/>
          )}

          {selectedTab === 'level' && (
            <>
              <div className="mb-4 flex gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select or Create Level
                  </label>
                  <div className="flex gap-2">
                    {existingLevels.length > 0 && (
                      <select
                        className="p-2 border rounded"
                        value={selectedLevel}
                        onChange={(e) => setSelectedLevel(parseInt(e.currentTarget.value))}
                      >
                        <option value="">Select Level...</option>
                        {existingLevels.map((level) => (
                          <option key={level} value={level}>
                            Level {level}
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      type="number"
                      min="1"
                      className="w-32 p-2 border rounded"
                      value={selectedLevel}
                      onChange={(e) => setSelectedLevel(parseInt(e.currentTarget.value) || 1)}
                      placeholder="New Level #"
                    />
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                      onClick={() => setSelectedLevel(Math.max(...existingLevels, 0) + 1)}
                    >
                      New Level
                    </button>
                  </div>
                </div>
              </div>

              <HexGridEditor
                database={currentDatabase}
                levelNumber={selectedLevel}
                onLevelsChange={loadLevels}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}