

import { Search } from "lucide-preact";
import { ArrowDown, ArrowUp } from "lucide-preact";
import { useEffect, useState } from "preact/hooks";

const SimpleTooltip = ({ children, tooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block"
         onMouseEnter={() => setShowTooltip(true)}
         onMouseLeave={() => setShowTooltip(false)}>
      {children}
      {showTooltip && (
        <div className="absolute z-50 bottom-full mb-2 left-1/2 transform -translate-x-1/2">
          <div className="bg-gray-900 text-white px-2 py-1 rounded text-xs whitespace-nowrap">
            {tooltip}
          </div>
          <div className="border-t-4 border-gray-900 w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent absolute left-1/2 transform -translate-x-1/2"></div>
        </div>
      )}
    </div>
  );
};

const FilterToggleButton = ({ active, onClick, children, tooltip }) => (
  <SimpleTooltip tooltip={tooltip}>
    <button
      onClick={onClick}
      className={`px-2 py-1 text-xs rounded-md ${
        active 
          ? 'bg-blue-500 text-white' 
          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
      }`}
    >
      {children}
    </button>
  </SimpleTooltip>
);

const NumericFilter = ({ label, value, onChange, tooltip }) => (
  <SimpleTooltip tooltip={tooltip}>
    <div className="flex items-center gap-2">
      <label className="text-sm text-gray-600 w-16">{label}:</label>
      <input
        type="number"
        min="0"
        value={value}
        onChange={(e) => onChange(e.target.value ? Number(e.target.value) : '')}
        className="w-16 px-2 py-1 border rounded text-sm"
        placeholder="Any"
      />
    </div>
  </SimpleTooltip>
);

const CardFilters = ({ onFilterChange, handleCreate }) => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    movement: '',
    attack: '',
    life: '',
    range: '',
    triggers: new Set(),
    effectTypes: new Set()
  });

  // Effect Trigger options with icons and descriptions
  const triggerOptions = [
    { id: 'Strike', icon: '⚔️', label: 'Strike', tooltip: 'Triggered when this unit attacks' },
    { id: 'Wrath', icon: '💢', label: 'Wrath', tooltip: 'Triggered when this unit is damaged' },
    { id: 'Legacy', icon: '💀', label: 'Legacy', tooltip: 'Triggered when this unit dies' },
    { id: 'Revenge', icon: '🏴', label: 'Revenge', tooltip: 'Triggered when a friendly unit dies' },
    { id: 'Conquest', icon: '👑', label: 'Conquest', tooltip: 'Triggered when an enemy unit dies' },
    { id: 'Vitality', icon: '💗', label: 'Vitality', tooltip: "Triggered when unit's health increases" },
    { id: 'Destiny', icon: '⭐', label: 'Destiny', tooltip: "Triggered when unit's attack increases" },
    { id: 'Awake', icon: '🌅', label: 'Awake', tooltip: 'Triggered before Initiative phase' },
    { id: 'Sleep', icon: '🌙', label: 'Sleep', tooltip: 'Triggered after Initiative phase' },
    { id: 'Prophecy', icon: '🔮', label: 'Prophecy', tooltip: 'Triggered when you draw a card' },
    { id: 'Sacrifice', icon: '🎭', label: 'Sacrifice', tooltip: 'Triggered when you discard a card' },
    { id: 'Omen', icon: '✨', label: 'Omen', tooltip: 'Triggered when you play a spell' },
    { id: 'Investment', icon: '💸', label: 'Investment', tooltip: 'Triggered when you lose X or more gold' },
    { id: 'Merchant', icon: '💰', label: 'Merchant', tooltip: 'Triggered when you gain gold' },
    { id: 'Cast', icon: '🎯', label: 'Cast', tooltip: 'Triggers an immediate effect' },
    { id: 'Blessing', icon: '🙏', label: 'Blessing', tooltip: 'Buffs a single unit' },
    { id: 'Rite', icon: '⭕', label: 'Rite', tooltip: 'Affects a selected ground area' },
    { id: 'Rally', icon: '🚩', label: 'Rally', tooltip: 'Buffs all friendly units' }
  ];

  // Effect Type options with icons and descriptions
  const effectTypeOptions = [
    { id: 'Damage', icon: '⚡', label: 'Damage', tooltip: 'Deals direct damage' },
    { id: 'DefaultAttack', icon: '⚔️', label: 'Default Attack', tooltip: 'Normal initiative attack' },
    { id: 'ResourceGain', icon: '💰', label: 'Resource Gain', tooltip: 'Generates resources' },
    { id: 'Buff', icon: '⬆️', label: 'Buff', tooltip: 'Increases unit stats' },
    { id: 'SummonUnit', icon: '🌟', label: 'Summon Unit', tooltip: 'Creates new units' },
    { id: 'AreaEffect', icon: '🎯', label: 'Area Effect', tooltip: 'Affects multiple hexes' },
    { id: 'ShopToHand', icon: '🎴', label: 'Shop to Hand', tooltip: 'Moves cards from shop to hand' },
    { id: 'MoveToZone', icon: '↔️', label: 'Move to Zone', tooltip: 'Moves cards between zones' }
  ];

  const handleNumericFilterChange = (key, value) => {
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const toggleTrigger = (triggerId) => {
    const newTriggers = new Set(filters.triggers);
    if (newTriggers.has(triggerId)) {
      newTriggers.delete(triggerId);
    } else {
      newTriggers.add(triggerId);
    }
    const newFilters = { ...filters, triggers: newTriggers };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const toggleEffectType = (effectTypeId) => {
    const newEffectTypes = new Set(filters.effectTypes);
    if (newEffectTypes.has(effectTypeId)) {
      newEffectTypes.delete(effectTypeId);
    } else {
      newEffectTypes.add(effectTypeId);
    }
    const newFilters = { ...filters, effectTypes: newEffectTypes };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
        <input
          type="text"
          placeholder="Search cards..."
          className="w-full pl-10 pr-4 py-2 border rounded"
          value={filters.searchTerm}
          onChange={(value) => handleNumericFilterChange('searchTerm', value.target.value)}
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <NumericFilter 
          label="Movement"
          value={filters.movement}
          onChange={(value) => handleNumericFilterChange('movement', value)}
          tooltip="Filter by unit's movement speed"
        />
        <NumericFilter 
          label="Attack"
          value={filters.attack}
          onChange={(value) => handleNumericFilterChange('attack', value)}
          tooltip="Filter by unit's attack value"
        />
              <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={handleCreate}
        >
          Create New Unit
        </button>
        <NumericFilter 
          label="Life"
          value={filters.life}
          onChange={(value) => handleNumericFilterChange('life', value)}
          tooltip="Filter by unit's life points"
        />
        <NumericFilter 
          label="Range"
          value={filters.range}
          onChange={(value) => handleNumericFilterChange('range', value)}
          tooltip="Filter by unit's attack range"
        />

<button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={handleCreate}
        >
          Create New Spell
        </button>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-2">Effect Triggers</h3>
        <div className="flex flex-wrap gap-2">
          {triggerOptions.map((trigger) => (
            <FilterToggleButton
              key={trigger.id}
              active={filters.triggers.has(trigger.id)}
              onClick={() => toggleTrigger(trigger.id)}
              tooltip={trigger.tooltip}
            >
              {trigger.icon} {trigger.label}
            </FilterToggleButton>
          ))}
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-2">Effect Types</h3>
        <div className="flex flex-wrap gap-2">
          {effectTypeOptions.map((effectType) => (
            <FilterToggleButton
              key={effectType.id}
              active={filters.effectTypes.has(effectType.id)}
              onClick={() => toggleEffectType(effectType.id)}
              tooltip={effectType.tooltip}
            >
              {effectType.icon} {effectType.label}
            </FilterToggleButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardFilters;