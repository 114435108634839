import CardActions from "./CardActions.tsx";
import CardCounter from "./CardCounter.tsx";
import CardEffects from "./CardEffects.tsx";
import CardHeader from "./CardHeader.tsx";
import CardStats from "./CardStats.tsx";
import { ExternalLink } from "lucide-preact";

interface CardItemProps {
  card: Card;
  onCardClick: (e: MouseEvent, cardName: string) => void;
  onEdit: () => void;
  onDelete: () => void;
  getCardCount: (cardName: string, deckType: string) => number;
  updateCardCount: (
    cardName: string,
    deckType: string,
    increment: boolean,
  ) => void;
  getCardImageUrl: (card: Card) => string;
}

const CardItem = ({
  card,
  onCardClick,
  onEdit,
  onDelete,
  getCardCount,
  updateCardCount,
  getCardImageUrl,
}: CardItemProps) => {
  const handleDebugClick = (e: any,card:any) => {
    if(card.Life){

      window.open(`https://debug.dev.mehmke.click/NormalMapViewer/units%2F${encodeURIComponent(card.Name)}`, '_blank');
    }
    else{
      window.open(`https://debug.dev.mehmke.click/NormalMapViewer/spells%2F${encodeURIComponent(card.Name)}`, '_blank');

    }
    e.stopPropagation(); // Prevent card click event

  };

  return (
    <div
      className="relative h-48 rounded-lg overflow-hidden shadow-md cursor-pointer hover:shadow-lg transition-shadow"
      onClick={(e) => onCardClick(e, card.Name)}
      onContextMenu={(e) => onCardClick(e, card.Name)}
    >
      <img
        src={getCardImageUrl(card)}
        alt={card.Name}
        onError={(e) => {
          e.target.src = "/api/placeholder/400/320";
          e.target.className =
            "absolute inset-0 w-full h-full object-cover opacity-20";
        }}
        className="absolute inset-0 w-full h-full object-cover opacity-20"
      />

      <div className="relative h-full p-3 bg-opacity-90">
        <button
          onClick={(e)=>handleDebugClick(e,card)}
          title="Open in debug viewer"
        >
          <ExternalLink size={16} />
        </button>

        <CardHeader name={card.Name} cost={card.Cost} />
        <CardActions onEdit={onEdit} onDelete={() => onDelete(card)} />

        {!card.Type && (
          <CardStats
            attack={card.Attack}
            range={card.Range}
            life={card.Life}
            movementSpeed={card.MovementSpeed}
          />
        )}
        {card.Cost !== undefined && (
          <div className="text-sm text-gray-600 mb-2">
            Cost: {card.Cost}
          </div>
        )}

        {card.EffectExpressions?.length > 0 && (
          <CardEffects effects={card.EffectExpressions} />
        )}

        <div className="absolute bottom-2 left-2">
          <CardCounter
            count={getCardCount(card.Name, "PlayerStartDeck")}
            onIncrement={() =>
              updateCardCount(card.Name, "PlayerStartDeck", true)}
            onDecrement={() =>
              updateCardCount(card.Name, "PlayerStartDeck", false)}
          />
        </div>

        <div className="absolute bottom-2 right-2">
          <CardCounter
            count={getCardCount(card.Name, "Shop1Deck")}
            onIncrement={() => updateCardCount(card.Name, "Shop1Deck", true)}
            onDecrement={() => updateCardCount(card.Name, "Shop1Deck", false)}
          />
        </div>
      </div>
    </div>
  );
};

export default CardItem;