import { ArrowDown, ArrowUp } from "lucide-preact";

interface CardCounterProps {
    count: number;
    onIncrement: () => void;
    onDecrement: () => void;
  }
  
  const CardCounter = ({ count, onIncrement, onDecrement }: CardCounterProps) => (
    <div className="flex items-center gap-1 bg-white bg-opacity-90 p-1 rounded">
      <span className="text-sm font-medium w-6 text-center">{count}</span>
      <div className="flex flex-col">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onIncrement();
          }}
          className="p-0.5 hover:bg-gray-200 rounded"
        >
          <ArrowUp className="w-3 h-3" />
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDecrement();
          }}
          className="p-0.5 hover:bg-gray-200 rounded"
        >
          <ArrowDown className="w-3 h-3" />
        </button>
      </div>
    </div>
  );

  export default CardCounter;