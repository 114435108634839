import { useEffect, useState } from "preact/hooks";
import { ArrowDown, ArrowUp, Save, Search, X } from "lucide-preact";

export const DeckInventoryList = ({ 
    title, 
    entries, 
    allCards, 
    onRemove, 
    onUpdateCount, 
    type,
    className = ""
  }) => {
    const getCardImageUrl = (cardName) => {
      const card = allCards[cardName];
      if (!card) return "/api/placeholder/400/320";
      const encodedName = encodeURIComponent(cardName);
      return `https://game-files.mehmke.click/assets/${card.Type === "Spell" ? "spells" : "units"}/${encodedName}/cardImage.jpeg`;
    };
  
    return (
      <div className={`h-screen border rounded-lg p-4 bg-white shadow-sm ${className}`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">{title}</h2>
          <div className="text-sm text-gray-500">
            Cards: {entries.reduce((sum, entry) => sum + entry.Amount, 0)}
          </div>
        </div>
        
        <div className="space-y-3 h-max overflow-y-auto">
          {entries.map((entry) => {
            const card = allCards[entry.Card];
            if (!card) return null;
  
            return (
              <div key={entry.Card} className="border rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow">
                <div className="p-4">
                  <div className="flex gap-4">
                    <div className="w-20 h-20 flex-shrink-0">
                      <img
                        src={getCardImageUrl(entry.Card)}
                        alt={entry.Card}
                        className="w-full h-full object-cover rounded-lg"
                        onError={(e) => {
                          e.target.src = "/api/placeholder/400/320";
                          e.target.className += " opacity-50";
                        }}
                      />
                    </div>
                    
                    <div className="flex-grow min-w-0">
                      <div className="flex justify-between items-start mb-2">
                        <h3 className="font-semibold text-gray-800 truncate">{entry.Card}</h3>
                        <div className="flex items-center gap-2 flex-shrink-0">
                          <div className="flex items-center border rounded-lg bg-gray-50">
                            <button
                              onClick={() => onUpdateCount(entry.Card, false)}
                              className="p-1 hover:bg-gray-100 rounded-l-lg border-r"
                            >
                              <ArrowDown className="w-4 h-4" />
                            </button>
                            <span className="px-3 py-1 font-medium">
                              {entry.Amount}
                            </span>
                            <button
                              onClick={() => onUpdateCount(entry.Card, true)}
                              className="p-1 hover:bg-gray-100 rounded-r-lg border-l"
                            >
                              <ArrowUp className="w-4 h-4" />
                            </button>
                          </div>
                          <button
                            onClick={() => onRemove(entry.Card)}
                            className="p-1 hover:bg-red-100 rounded-lg"
                            title="Remove card"
                          >
                            <X className="w-4 h-4 text-red-500" />
                          </button>
                        </div>
                      </div>
                      
                      {!card.Type && (
                        <div className="grid grid-cols-2 gap-2 text-sm text-gray-600 mb-2">
                          <div>ATK: {card.Attack}</div>
                          <div>RNG: {card.Range}</div>
                          <div>HP: {card.Life}</div>
                          <div>SPD: {card.MovementSpeed}</div>
                        </div>
                      )}
                      
                      {card.Cost !== undefined && (
                        <div className="text-sm text-gray-600 mb-2">
                          Cost: {card.Cost}
                        </div>
                      )}
                      
                      {card.EffectExpressions?.length > 0 && (
                        <div className="text-sm text-gray-600">
                          <div className="font-medium">Effects:</div>
                          <ul className="list-disc pl-4 space-y-1">
                            {card.EffectExpressions.map((effect, index) => (
                              <li key={index} className="truncate">{effect}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          
          {entries.length === 0 && (
            <div className="text-center text-gray-500 py-8">
              No cards in this {type}
            </div>
          )}
        </div>
      </div>
    );
  };
  