import { useEffect, useState } from "preact/hooks";

interface Position {
  x: number;
  y: number;
}

interface UnitPlacement {
  UnitName: string;
  Position: Position;
}

interface Level {
  units: UnitPlacement[];
  deck: UnitPlacement[];
  hand: UnitPlacement[];
  graveyard: UnitPlacement[];
}

type EditorSection = "board" | "decks";

interface HexGridEditorProps {
  database: string;
  levelNumber: number;
  onLevelsChange: () => void;
}

// Grid constants
const ROWS = 6;
const COLS = 6;
const HEX_SIZE = 40;
const WIDTH = Math.sqrt(3) * HEX_SIZE;
const HEIGHT = 2 * HEX_SIZE;

const CardSection = ({ 
  title, 
  cards, 
  availableUnits, 
  onAdd, 
  onRemove 
}: { 
  title: string;
  cards: UnitPlacement[];
  availableUnits: string[];
  onAdd: (unitName: string) => void;
  onRemove: (index: number) => void;
}) => (
  <div className="border rounded-lg p-4 bg-white shadow-sm">
    <h3 className="text-lg font-semibold mb-4 text-gray-800">{title}</h3>
    <div className="space-y-2">
      <select
        className="w-full p-2 border rounded-md bg-white hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
        value=""
        onChange={(e) => {
          if (e.currentTarget.value) {
            onAdd(e.currentTarget.value);
            e.currentTarget.value = "";
          }
        }}
      >
        <option value="">Add card...</option>
        {availableUnits.map((unit) => (
          <option key={unit} value={unit}>{unit}</option>
        ))}
      </select>

      <div className="mt-3 space-y-2 max-h-48 overflow-y-auto">
        {cards.map((card, index) => (
          <div key={index} 
               className="flex justify-between items-center p-2 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors">
            <span className="text-gray-700">{card.UnitName}</span>
            <button
              className="text-red-500 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50 transition-colors"
              onClick={() => onRemove(index)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default function HexGridEditor({ database, levelNumber, onLevelsChange }: HexGridEditorProps) {
  const [level, setLevel] = useState<Level>({
    units: [],
    deck: [],
    hand: [],
    graveyard: [],
  });
  const [availableUnits, setAvailableUnits] = useState<string[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>("");
  const [error, setError] = useState("");
  const [existingLevels, setExistingLevels] = useState<number[]>([]);
  const [activeSection, setActiveSection] = useState<EditorSection>("board");
  const [isNewLevel, setIsNewLevel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    loadData();
  }, [database, levelNumber]);

  const loadData = async () => {
    try {
      const response = await fetch(`/api/levelEditor?database=${encodeURIComponent(database)}&levelNumber=${levelNumber}`);
      if (!response.ok) throw new Error("Failed to load data");

      const data = await response.json();
      setAvailableUnits(data.unitNames);
      setSelectedUnit(data.unitNames[0] || "");
      setLevel({
        units: data.level.units || [],
        deck: data.level.deck || [],
        hand: data.level.hand || [],
        graveyard: data.level.graveyard || [],
      });
      setExistingLevels(data.existingLevels);
      setIsNewLevel(!data.existingLevels.includes(levelNumber));
      setError("");
    } catch (err) {
      setError("Failed to load level data");
    }
  };

  const saveLevel = async () => {
    try {
      setIsSaving(true);
      const basePath = database.split("/carddatabase")[0];
      const response = await fetch("/api/level", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ database: basePath, levelNumber, level }),
      });

      if (!response.ok) throw new Error("Failed to save level");
      onLevelsChange();
      setError("");
    } catch (err) {
      setError("Failed to save level");
    } finally {
      setIsSaving(false);
    }
  };

  const deleteLevel = async () => {
    if (!confirm(`Are you sure you want to delete Level ${levelNumber}?`)) return;

    try {
      const basePath = database.split("/carddatabase")[0];
      const response = await fetch("/api/level", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ database: basePath, levelNumber }),
      });

      if (!response.ok) throw new Error("Failed to delete level");
      onLevelsChange();
      setError("");
    } catch (err) {
      setError("Failed to delete level");
    }
  };

  const getHexPoints = (centerX: number, centerY: number): string => {
    const points = [];
    for (let i = 0; i < 6; i++) {
      const angle = (Math.PI / 3) * i + Math.PI / 6;
      const x = centerX + HEX_SIZE * Math.cos(angle);
      const y = centerY + HEX_SIZE * Math.sin(angle);
      points.push(`${x},${y}`);
    }
    return points.join(" ");
  };

  const getHexPosition = (col: number, row: number) => ({
    x: col * WIDTH + (row % 2 === 1 ? WIDTH * 0.5 : 0),
    y: row * HEIGHT * 0.75
  });

  const getUnitAtPosition = (x: number, y: number) => 
    level.units.find(unit => unit.Position.x === x && unit.Position.y === y);

  const handleHexClick = (col: number, row: number) => {
    if (!selectedUnit) return;

    const existingUnit = getUnitAtPosition(col, row);
    setLevel({
      ...level,
      units: existingUnit
        ? level.units.filter(u => !(u.Position.x === col && u.Position.y === row))
        : [...level.units, { UnitName: selectedUnit, Position: { x: col, y: row } }]
    });
  };

  return (
    <div className="p-4 bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">
            Level {levelNumber} Editor
            {isNewLevel && (
              <span className="ml-2 text-sm font-normal bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full">
                New Level
              </span>
            )}
          </h2>
        </div>

        <nav className="flex border-b border-gray-200 mb-6">
          <button
            className={`py-2 px-4 -mb-px ${
              activeSection === "board"
                ? "border-b-2 border-blue-500 text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => setActiveSection("board")}
          >
            Board Layout
          </button>
          <button
            className={`py-2 px-4 -mb-px ${
              activeSection === "decks"
                ? "border-b-2 border-blue-500 text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => setActiveSection("decks")}
          >
            Deck Management
          </button>
        </nav>

        {activeSection === "board" && (
          <div className="bg-white rounded-lg shadow p-6">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Unit to Place
              </label>
              <select
                className="w-full p-2 border rounded-md"
                value={selectedUnit}
                onChange={(e) => setSelectedUnit(e.currentTarget.value)}
              >
                {availableUnits.map((unit) => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </select>
            </div>

            <div className="mb-4 overflow-auto">
              <svg
                width={WIDTH * (COLS + 1)}
                height={HEIGHT * (ROWS * 0.75 + 0.25)}
                viewBox={`0 0 ${WIDTH * (COLS + 1)} ${HEIGHT * (ROWS * 0.75 + 0.25)}`}
                className="border rounded-lg"
              >
                {Array.from({ length: ROWS }, (_, row) =>
                  Array.from({ length: COLS + (ROWS % 2 === 1 ? 1 : 0) }, (_, col) => {
                    const pos = getHexPosition(col, row);
                    const unit = getUnitAtPosition(col, row);
                    return (
                      <g key={`${col}-${row}`} onClick={() => handleHexClick(col, row)}>
                        <polygon
                          points={getHexPoints(pos.x + WIDTH / 2, pos.y + HEIGHT / 2)}
                          fill={unit ? "#e5e7eb" : "white"}
                          stroke="#4b5563"
                          strokeWidth="2"
                          className="cursor-pointer hover:fill-gray-200 transition-colors"
                        />
                        {unit && (
                          <text
                            x={pos.x + WIDTH / 2}
                            y={pos.y + HEIGHT / 2}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            className="text-xs pointer-events-none font-medium"
                          >
                            {unit.UnitName}
                          </text>
                        )}
                      </g>
                    );
                  })
                )}
              </svg>
            </div>
          </div>
        )}

        {activeSection === "decks" && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <CardSection
              title="Main Deck"
              cards={level.deck}
              availableUnits={availableUnits}
              onAdd={(unitName) => setLevel({...level, deck: [...level.deck, { UnitName: unitName }]})}
              onRemove={(index) => {
                const newDeck = [...level.deck];
                newDeck.splice(index, 1);
                setLevel({...level, deck: newDeck});
              }}
            />
            <CardSection
              title="Starting Hand"
              cards={level.hand}
              availableUnits={availableUnits}
              onAdd={(unitName) => setLevel({...level, hand: [...level.hand, { UnitName: unitName }]})}
              onRemove={(index) => {
                const newHand = [...level.hand];
                newHand.splice(index, 1);
                setLevel({...level, hand: newHand});
              }}
            />
            <CardSection
              title="Starting Graveyard"
              cards={level.graveyard}
              availableUnits={availableUnits}
              onAdd={(unitName) => setLevel({...level, graveyard: [...level.graveyard, { UnitName: unitName }]})}
              onRemove={(index) => {
                const newGraveyard = [...level.graveyard];
                newGraveyard.splice(index, 1);
                setLevel({...level, graveyard: newGraveyard});
              }}
            />
          </div>
        )}

        {error && (
          <div className="mt-4 bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <div className="mt-6 flex justify-between">
          <button
            className={`px-4 py-2 rounded-md text-white ${
              isSaving 
                ? "bg-blue-400 cursor-not-allowed" 
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            onClick={saveLevel}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : (isNewLevel ? "Create Level" : "Save Changes")}
          </button>

          {!isNewLevel && (
            <button
              className="px-4 py-2 rounded-md text-white bg-red-500 hover:bg-red-600"
              onClick={deleteLevel}
            >
              Delete Level
            </button>
          )}
        </div>
      </div>
    </div>
  );
}