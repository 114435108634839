// CardHeader.tsx
interface CardHeaderProps {
  name: string;
  cost: number;
}

const CardHeader = ({ name, cost }: CardHeaderProps) => (
  <div className="flex justify-between items-start">
    <h3 className="font-medium text-lg truncate max-w-[70%]">{name}</h3>
    <span className="text-sm bg-blue-100 text-blue-800 px-2 py-0.5 rounded">
      Cost: {cost}
    </span>
  </div>
);
export default CardHeader;
