interface CardActionsProps {
    onEdit: () => void;
    onDelete: () => void;
  }
  
  const CardActions = ({ onEdit, onDelete }: CardActionsProps) => (
    <div className="absolute top-2 right-2 flex gap-2">
      <button
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
        className="p-1 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Edit
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        className="p-1 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Delete
      </button>
    </div>
  );
  export default CardActions;
  