import { useEffect, useState } from "preact/hooks";
import { ArrowDown, ArrowUp, Save, Search, X } from "lucide-preact";
import CardGrid from "./CardGrid.tsx";
import { DeckInventoryList } from "./DeckInventoryList.tsx";
interface CardEntry {
  Card: string;
  Amount: number;
}

interface CommanderEntry {
  Commander: string;
  AdditionDeckCards: CardEntry[];
}

interface Collection {
  PlayerStartDeck: CardEntry[];
  Shop1Deck: CardEntry[];
  Commander: CommanderEntry[];
}

interface Card {
  Name: string;
  Type?: string;
  Attack?: number;
  Range?: number;
  Life?: number;
  MovementSpeed?: number;
  Initiative: number;
  Cost: number;
  EffectExpressions?: string[];
}

interface CollectionEditorProps {
  database: string;
  onCardUpdate: (fileType: string, cardData: string) => void;
}

export default function CollectionEditor({ database, onCardUpdate }: CollectionEditorProps) {
  const [collection, setCollection] = useState<Collection>({
    PlayerStartDeck: [],
    Shop1Deck: [],
    Commander: []
  });
  

  const [builds, setBuilds] = useState<string[]>([]);
  const [buildError, setBuildError] = useState<string | null>(null);
  const [buildLoading, setBuildLoading] = useState(false);
  const [selectedDeck, setSelectedDeck] = useState<string>("player");
  const [allCards, setAllCards] = useState<Record<string, Card>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [saveStatus, setSaveStatus] = useState<"idle" | "saving" | "saved" | "error">("idle");

  // Get the currently selected deck's cards
  const getCurrentDeck = () => {
    if (selectedDeck === "player") {
      return collection.PlayerStartDeck;
    }
    const commander = collection.Commander.find(cmd => cmd.Commander === selectedDeck);
    return commander ? commander.AdditionDeckCards : [];
  };
  const loadBuilds = async () => {
    try {
      setBuildLoading(true);
      const response = await fetch("/api/versionPath");
      if (!response.ok) throw new Error("Failed to load builds");

      const buildUrls = await response.json();
      if (!Array.isArray(buildUrls)) {
        throw new Error("Invalid build data format");
      }

      setBuilds(buildUrls);
      setBuildError(null);
    } catch (err) {
      setBuildError(
        err instanceof Error ? err.message : "Failed to load builds",
      );
      console.error(err);
    } finally {
      setBuildLoading(false);
    }
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/collection?database=${encodeURIComponent(database)}`);
      if (!response.ok) throw new Error("Failed to load collection");

      const data = await response.json();
      if (data.error) throw new Error(data.error);

      const _allCards: Record<string, Card> = {};
      for (const item of data.availableCards) {
        _allCards[item.Name] = item;
      }
      setAllCards(_allCards);
      setCollection(data.collection);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to load collection data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    loadBuilds();
  }, [database]);

  const saveCollection = async () => {
    try {
      setSaveStatus("saving");
      const response = await fetch("/api/collection", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ database, collection }),
      });

      if (!response.ok) throw new Error("Failed to save collection");
      const data = await response.json();
      if (data.error) throw new Error(data.error);

      setSaveStatus("saved");
      setTimeout(() => setSaveStatus("idle"), 2000);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to save collection");
      setSaveStatus("error");
    }
  };

  const updatePlayerDeck = (cardName: string, increment: boolean) => {
    setCollection(prev => {
      const existingCard = prev.PlayerStartDeck.find(card => card.Card === cardName);
      
      if (existingCard) {
        const newAmount = increment ? existingCard.Amount + 1 : Math.max(0, existingCard.Amount - 1);
        if (newAmount === 0) {
          return {
            ...prev,
            PlayerStartDeck: prev.PlayerStartDeck.filter(card => card.Card !== cardName)
          };
        }
        return {
          ...prev,
          PlayerStartDeck: prev.PlayerStartDeck.map(card =>
            card.Card === cardName ? { ...card, Amount: newAmount } : card
          )
        };
      } else if (increment) {
        return {
          ...prev,
          PlayerStartDeck: [...prev.PlayerStartDeck, { Card: cardName, Amount: 1 }]
        };
      }
      return prev;
    });
  };

  const updateCommanderDeck = (cardName: string, increment: boolean) => {
    setCollection(prev => ({
      ...prev,
      Commander: prev.Commander.map(cmd => {
        if (cmd.Commander !== selectedDeck) return cmd;
        
        const existingCard = cmd.AdditionDeckCards.find(card => card.Card === cardName);
        let updatedCards;
        
        if (existingCard) {
          const newAmount = increment ? existingCard.Amount + 1 : Math.max(0, existingCard.Amount - 1);
          if (newAmount === 0) {
            updatedCards = cmd.AdditionDeckCards.filter(card => card.Card !== cardName);
          } else {
            updatedCards = cmd.AdditionDeckCards.map(card =>
              card.Card === cardName ? { ...card, Amount: newAmount } : card
            );
          }
        } else if (increment) {
          updatedCards = [...cmd.AdditionDeckCards, { Card: cardName, Amount: 1 }];
        } else {
          return cmd;
        }
        
        return { ...cmd, AdditionDeckCards: updatedCards };
      })
    }));
  };

  const updateShopDeck = (cardName: string, increment: boolean) => {
    setCollection(prev => {
      const existingCard = prev.Shop1Deck.find(card => card.Card === cardName);
      
      if (existingCard) {
        const newAmount = increment ? existingCard.Amount + 1 : Math.max(0, existingCard.Amount - 1);
        if (newAmount === 0) {
          return {
            ...prev,
            Shop1Deck: prev.Shop1Deck.filter(card => card.Card !== cardName)
          };
        }
        return {
          ...prev,
          Shop1Deck: prev.Shop1Deck.map(card =>
            card.Card === cardName ? { ...card, Amount: newAmount } : card
          )
        };
      } else if (increment) {
        return {
          ...prev,
          Shop1Deck: [...prev.Shop1Deck, { Card: cardName, Amount: 1 }]
        };
      }
      return prev;
    });
  };

  if (loading) {
    return <div className="flex items-center justify-center h-64">Loading collection...</div>;
  }

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <label htmlFor="deck-select" className="font-medium">Select Deck:</label>
          <select
            id="deck-select"
            value={selectedDeck}
            onChange={(e) => setSelectedDeck(e.target.value)}
            className="px-4 py-2 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
          >
            <option value="player">Player Start Deck</option>
            {collection.Commander.map((cmd) => (
              <option key={cmd.Commander} value={cmd.Commander}>
                {cmd.Commander}
              </option>
            ))}
          </select>
        </div>
        {builds.map((buildUrl, index) => (
          <button
            key={buildUrl}
            onClick={() => window.open(buildUrl, "_blank")}
            className="px-4 py-2 m-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {buildUrl.split("/").pop()}
          </button>
        ))}

        {buildLoading && <p>Loading builds...</p>}
        {buildError && <p className="text-red-500">Error: {buildError}</p>}
        <button
          onClick={saveCollection}
          disabled={saveStatus === "saving"}
          className={`flex items-center gap-2 px-4 py-2 rounded ${
            saveStatus === "saving" ? "bg-gray-400" :
            saveStatus === "saved" ? "bg-green-500" :
            saveStatus === "error" ? "bg-red-500" :
            "bg-blue-500"
          } text-white`}
        >
          <Save className="w-4 h-4" />
          {saveStatus === "saving" ? "Saving..." :
           saveStatus === "saved" ? "Saved!" :
           saveStatus === "error" ? "Error!" :
           "Save Changes"}
        </button>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-3 space-y-4">
          <DeckInventoryList
            title={selectedDeck === "player" ? "Player Start Deck" : `${selectedDeck} Additional Cards`}
            entries={getCurrentDeck()}
            allCards={allCards}
            onRemove={(cardName) => 
              selectedDeck === "player" 
                ? updatePlayerDeck(cardName, false)
                : updateCommanderDeck(cardName, false)
            }
            onUpdateCount={(cardName, increment) =>
              selectedDeck === "player"
                ? updatePlayerDeck(cardName, increment)
                : updateCommanderDeck(cardName, increment)
            }
            type="deck"
          />
        </div>

        <div className="col-span-6 border rounded-lg p-4">
          <CardGrid
            allCards={Object.values(allCards)}
            updateCardCount={(cardName, isShopDeck, increment) => {
              if (isShopDeck === "Shop1Deck") {
                updateShopDeck(cardName, increment);
              } else {
                selectedDeck === "player"
                  ? updatePlayerDeck(cardName, increment)
                  : updateCommanderDeck(cardName, increment);
              }
            }}
            collection={collection}
            database={database}
            onCardUpdate={onCardUpdate}
          />
        </div>

        <div className="col-span-3 border rounded-lg p-4">
          <DeckInventoryList
            title="Shop Deck"
            entries={collection.Shop1Deck}
            allCards={allCards}
            onRemove={(cardName) => updateShopDeck(cardName, false)}
            onUpdateCount={(cardName, increment) => updateShopDeck(cardName, increment)}
            type="shop"
          />
        </div>
      </div>
    </div>
  );
}