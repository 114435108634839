import { Search } from "lucide-preact";
import { ArrowDown, ArrowUp } from "lucide-preact";
import { useState } from "preact/hooks";
import CardFilters from "./CardFilters.tsx";
import CardItem from "./CardItem.tsx";
import CardModal from "../CardSetEditor/CardModal.tsx";
import { Card } from "../CardManager.tsx";

const CardGrid = ({
  allCards,
  updateCardCount,
  collection,
  onCardUpdate,
  database,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isNewCard, setIsNewCard] = useState(false);
  const [filteredCards, setFilteredCards] = useState(allCards);
  const [redrawTimestamps, setRedrawTimestamps] = useState({});
  const getEmptyCard = (type) => {
    if (type === "spells") {
      return {
        Name: "",
        Type: "Spell",
        Cost: 1,
        Initiative: -1,
        Effects: [{
          Trigger: "Cast",
          Type: "ResourceGain",
          Description: "",
        }],
      };
    }
    return {
      Name: "",
      Attack: 1,
      Range: 1,
      Life: 1,
      MovementSpeed: 1,
      Initiative: 5,
      Cost: 1,
      Effects: [],
    };
  };

  const handleCreate = (type: string) => {
    setSelectedCard(getEmptyCard(type));
    setIsNewCard(true);
    setModalOpen(true);
  };

  const handleEdit = (card) => {
    setSelectedCard(card);
    setIsNewCard(false);
    setModalOpen(true);
  };

  const handleSave = async (cardData: Card) => {
    const fileType = (cardData as any).Type == "Spell" ? "Spells" : "Units";
    if (isNewCard) {
      try {
        const response = await fetch("/api/createCard", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            database,
            fileType,
            newCard: cardData,
          }),
        });

        if (!response.ok) throw new Error("Failed to create card");
        const updatedCards = await response.json();
        onCardUpdate(fileType, JSON.stringify(updatedCards));
      } catch (err) {
        console.error("Failed to create card:", err);
      }
    } else {
      onCardUpdate(fileType, cardData);
    }
    setModalOpen(false);
    setIsNewCard(false);
    setRedrawTimestamps(prev => ({
      ...prev,
      [cardData.Name]: Date.now()
    }));
  };

  const handleDelete = async (cardData) => {
    if (!confirm(`Are you sure you want to delete ${cardData.Name}?`)) {
      return;
    }
    const activeTab = (cardData as any).Type == "Spell" ? "Spells" : "Units";
    try {
      const response = await fetch("/api/deleteCard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          database,
          fileType: activeTab,
          cardName: cardData.Name,
        }),
      });

      if (!response.ok) throw new Error("Failed to delete card");
      const updatedCards = await response.json();
      onCardUpdate(activeTab, JSON.stringify(updatedCards));
    } catch (err) {
      console.error("Failed to delete card:", err);
    }
  };

  const getCardImageUrl = (card) => {
    const encodedName = encodeURIComponent(card.Name);
    return `https://game-files.mehmke.click/assets/${
      card.Type === "Spell" ? "spells" : "units"
    }/${encodedName}/cardImage.jpeg`;
  };

  const getCardCount = (cardName, deckType) => {
    const deck = collection[deckType];
    const entry = deck.find((entry) => entry.Card === cardName);
    return entry ? entry.Amount : 0;
  };

  const applyFilters = (cards, filters) => {
    return cards.filter((card) => {
      // Text search
      if (!card.Name.toLowerCase().includes(filters.searchTerm.toLowerCase())) {
        return false;
      }

      // Numeric filters
      if (filters.movement !== "" && card.MovementSpeed !== filters.movement) {
        return false;
      }
      if (filters.attack !== "" && card.Attack !== filters.attack) return false;
      if (filters.life !== "" && card.Life !== filters.life) return false;
      if (filters.range !== "" && card.Range !== filters.range) return false;

      // Effect trigger filters
      if (filters.triggers.size > 0) {
        const hasMatchingTrigger = card.EffectExpressions?.some((effect) => {
          return Array.from(filters.triggers).some((trigger) =>
            effect.includes(`@${trigger}`)
          );
        });
        if (!hasMatchingTrigger) return false;
      }

      // Effect type filters
      if (filters.effectTypes.size > 0) {
        const hasMatchingType = card.EffectExpressions?.some((effect) => {
          return Array.from(filters.effectTypes).some((type) =>
            effect.includes(`{${type}}`)
          );
        });
        if (!hasMatchingType) return false;
      }

      return true;
    });
  };

  const handleFilterChange = (filters) => {
    setFilteredCards(applyFilters(allCards, filters));
  };
  const Counter = ({ count, onIncrement, onDecrement }) => (
    <div className="flex items-center gap-1 bg-white bg-opacity-90 p-1 rounded">
      <span className="text-sm font-medium w-6 text-center">{count}</span>
      <div className="flex flex-col">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onIncrement();
          }}
          className="p-0.5 hover:bg-gray-200 rounded"
        >
          <ArrowUp className="w-3 h-3" />
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDecrement();
          }}
          className="p-0.5 hover:bg-gray-200 rounded"
        >
          <ArrowDown className="w-3 h-3" />
        </button>
      </div>
    </div>
  );

  const handleCardClick = (e, cardName) => {
    e.preventDefault(); // Prevent default context menu on right click

    if (e.type === "click") {
      // Left click - add to shop
      updateCardCount(cardName, "Shop1Deck", true);
    } else if (e.type === "contextmenu") {
      // Right click - remove from shop
      updateCardCount(cardName, "Shop1Deck", false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <CardFilters
          onFilterChange={handleFilterChange}
          handleCreate={handleCreate}
        />
      </div>

      <div className="grid grid-cols-3 gap-4 max-h-screen overflow-y-auto">
        {filteredCards.map((card) => (
          <div
            key={`${card.Name}-${redrawTimestamps[card.Name] || "initial"}`}
            className="relative"
          >
            <CardItem
              key={card.Name}
              card={card}
              onCardClick={handleCardClick}
              onEdit={() => handleEdit(card)}
              onDelete={handleDelete}
              getCardCount={getCardCount}
              updateCardCount={updateCardCount}
              getCardImageUrl={getCardImageUrl}
            />
          </div>
        ))}
      </div>

      <CardModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setIsNewCard(false);
        }}
        onSave={handleSave}
        initialCard={selectedCard
          ? JSON.stringify(selectedCard, null, 2)
          : undefined}
      />
    </div>
  );
};

export default CardGrid;
